import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
    maxHeight: 345,
    height: "100%",
    overflow: "scroll",
  },
  media: {
    height: 140,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "visible",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const BlogPage = () => {
  const classes = useStyles();
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const handleOpenModal = (post) => {
    setSelectedPost(post);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedPost({});
    setOpenModal(false);
  };
  useEffect(() => {
    fetch(
      "https://public-api.wordpress.com/rest/v1.1/sites/genify8.wordpress.com/posts/"
    )
      .then((res) => res.json())
      .then((data) => {
        setPosts(data.posts);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (event, page) => setCurrentPage(page);

  return (
    <>
      <Helmet title="Blog | Genify" defer={false} />
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null", // cta, account, null
          path: "blogPage",
        }}
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <div
          class="pt-13 pt-lg-24 pb-lg-24 bg-default-1"
          style={{ marginTop: "5%" }}
        >
          <div class="container">
            <div className={classes.root}>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                className={classes.modal}
              >
                <div className={classes.paper}>
                  <h2>{selectedPost.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: selectedPost.content,
                    }}
                  ></p>
                </div>
              </Modal>
              <Grid container spacing={3}>
                {currentPosts.map((post) => (
                  <Grid item xs={12} sm={6} key={post.id}>
                    {/* <div style={{ height: "345px" }}> */}
                    <Card className={classes.card}>
                      <CardActionArea
                        button
                        onClick={() => handleOpenModal(post)}
                      >
                        <CardMedia
                          className={classes.media}
                          image={post.featured_image_src}
                          title={post.title}
                        />
                        <CardContent style={{ overflow: "scroll" }}>
                          <Typography gutterBottom variant="h5" component="h2">
                            {post.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: post.excerpt,
                              }}
                            />
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions className={classes.actions}>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => console.log("test")}
                        >
                          Share
                        </Button>
                        <Button size="small" color="primary">
                          Learn More
                        </Button>
                      </CardActions>
                    </Card>
                    {/* </div> */}
                  </Grid>
                ))}
              </Grid>
              <Pagination
                count={Math.ceil(posts.length / postsPerPage)}
                page={currentPage}
                onChange={paginate}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default BlogPage;
